
import { CHAIN_IDS } from 'config/web3/chains';

const TAROT_ADDRESSES: {
  [chainId: number]: string;
} = {
  [CHAIN_IDS.FANTOM]: '0xc5e2b037d30a390e62180970b3aa4e91868764cd',
  [CHAIN_IDS.OPTIMISM]: '0x375488f097176507e39b9653b88fdc52cde736bf',
  [CHAIN_IDS.BSC]: '0xa8cd6e4bf45724d3ac27f9e31e47ba4e399a7b52',
  [CHAIN_IDS.ETHEREUM_MAIN_NET]: '0x837d904a3799c0769079be9ecbaddf1abd4ccd6e',
  [CHAIN_IDS.ARBITRUM]: '0x6688b00f0c23a4a546beaae51a7c90c439895d48',
  [CHAIN_IDS.KAVA]: '0x165dbb08de0476271714952c3c1f068693bd60d7',
  [CHAIN_IDS.CANTO]: '0x2bf9b864cdc97b08b6d79ad4663e71b8ab65c45c',
  [CHAIN_IDS.AVAX]: '0x7f8d31a49212c62a11b6718aa0b612e129256553',
  [CHAIN_IDS.POLYGON]: '0xd23ed8ca350ce2631f7ecdc5e6bf80d0a1debb7b',
  [CHAIN_IDS.ZKSYNC]: '0x4c3861906b24a72adc944798c22cc450443a40ac'
};

const TAROT_V2_ADDRESSES: {
  [chainId: number]: string;
} = {
  [CHAIN_IDS.ETHEREUM_MAIN_NET]: '0xa10bf0aBa0C7953F279c4Cb8192d3B5dE5eA56E8',
  [CHAIN_IDS.FANTOM]: '0xb7C2ddB1EBAc1056231ef22c1b0A13988537a274',
  [CHAIN_IDS.OPTIMISM]: '0x1F514A61bcde34F94Bc39731235690ab9da737F7',
  [CHAIN_IDS.ARBITRUM]: '0x13278cD824D33A7aDB9f0a9A84ACA7C0D2DEEBf7',
  [CHAIN_IDS.CANTO]: '0xCAC45583c6990b6D0981F4707f00eB6a00214617',
  [CHAIN_IDS.BSC]: '0x982E609643794a31A07F5C5b142DD3a9cF0690be',
  [CHAIN_IDS.KAVA]: '0x2E4c7bf66d0484E44FeA0eC273b85A00Af92B2e3',
  [CHAIN_IDS.AVAX]: '0x5ECfeC22AA950CB5A3b4fd7249DC30B2BD160f18',
  [CHAIN_IDS.POLYGON]: '0xb092e1BF50f518b3Ebf7eD26A40015183aE36AC2',
  [CHAIN_IDS.ZKSYNC]: '0x7F2FD959013eEC5144269ac6edD0015Cb10968Fc',
  [CHAIN_IDS.BASE]: '0xF544251D25f3d243A36B07e7E7962a678f952691',
  [CHAIN_IDS.SONIC]: '0x981Bd9F77c8AAFC14EBC86769503f86A3CC29aF5' /* ,
  [CHAIN_IDS.MANTLE]: '0x981Bd9F77c8AAFC14EBC86769503f86A3CC29aF5',*/
};

const XTAROT_ADDRESSES: {
  [chainId: number]: string;
} = {
  [CHAIN_IDS.FANTOM]: '0x74d1d2a851e339b8cb953716445be7e8abdf92f4'
};

export {
  TAROT_ADDRESSES,
  TAROT_V2_ADDRESSES,
  XTAROT_ADDRESSES
};
